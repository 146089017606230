import React from "react";
import "../assets/css/style.css";
import dr from "../assets/images/DR.png";

const Shef = () => {
  return (
    <div>
      <div className="shef">
        <div id="shef">
          <h1 className="heading">
            About <span>Chef</span>
          </h1>
        </div>
        <div className="imageabout">
          <img
            className="drimage"
            width="280"
            height="400"
            src={dr}
            alt="vishal"
          />

          <div className="abouttext">
            <p className="para">
              Meet Vishal Gangurde, a passionate Pune-based chef whose culinary
              journey began with a background in food photography. Triumphantly
              completing a culinary arts diploma from APCA, Vishal mastered
              various cuisines, cooking techniques, and the art of pastry and
              baking.
            </p>
            <p className="para">
              His dedication shone during a transformative 6-month stint at Trou
              Aux Biches by Beachcomber in Mauritius. Vishal's culinary
              adventure also includes impactful contributions at Hilton and Taj
              in Bangalore, along with securing a noteworthy 2nd prize in a
              culinary competition at the Army Institute of Hotel Management.
            </p>
            <p className="para">
              Proudly holding the THSC Certified Commi Chef certificate and City
              and Guilds certifications at Levels 2 and 3, Vishal continues to
              leave an indelible mark in every kitchen he graces, showcasing his
              commitment to excellence in the culinary world.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shef;
