import React from "react";
import { ThaiCurryy } from "../../assets/MenuData";
import "../SidebarSubmenu/SidebarSub.css";
import vegImg from '../../assets/images/veg.png';
import nonvegImg from '../../assets/images/non.png';

const ThaiCurry = () => {

  const getImageUrl = (type) => {
    if (type === "Veg") {
      return vegImg; // Replace with your actual green image path
    } else if (type === "Non-Veg") {
      return nonvegImg; // Replace with your actual red image path
    } else {
      return type; // Replace with your actual default image path
    }
  };



  return (
    <>
      <div className="Container">
        <div className="Bubble">
          {ThaiCurryy.map((val, key) => {
            const imageUrl = getImageUrl(val.type);
            //console.log(val);
            return (
              <li key={key} className="BubbleList">
                <div className="row">
                  <ul className="nameprice">
                      <div className="photoname">
                      <img
                        width="100"
                        height="70"
                        src={val.Photo}
                        alt={val.Photo}
                        className="menuImg"
                      />
                      <div className="name">{val.Name}</div>
                      </div>
                      <div>
                        <div className="price">Price: {val.Price}</div>
                        <div className="type">
                          <img
                            width="30"
                            height="30"
                            src={imageUrl}
                            alt={`${val.type}`}
                          />
                        </div>
                      </div>
                    
                  </ul>
                  <div className="description">{val.Description}</div>
                </div>
              </li>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ThaiCurry;
