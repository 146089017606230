



export const ThaiCurryy = [
  {
    "Name": "Egg (Anda) Curry Rice Bowl",
    "Description": "Boiled egg tossed in rich onion tomato gravy served with steamed rice",
    "Price": 249,
    "type": "Non-Veg",
    "Photo": "MenuPhotos/AndaCurry.JPG"
  },
  {
    "Name": "Veg Thai Green Curry Rice Bowl",
    "Description": "Traditional coconut-based green Thai curry tossed with exotic vegetables served with choice of steamed rice",
    "Price": 299,
    "type": "Veg",
    "Photo": "MenuPhotos/GreenCurry.JPG"
  },
  {
    "Name": "Veg Thai Red Curry Rice Bowl",
    "Description": "Traditional coconut-based red Thai curry tossed with exotic vegetables served with choice of steamed rice",
    "Price": 299,
    "type": "Veg",
    "Photo": "MenuPhotos/RedChicken.JPG"
  },
  {
    "Name": "Red Chicken Thai Curry Rice Bowl",
    "Description": "Traditional red Thai curry infused in coconut milk tossed in chicken and exotic vegetables served with choice of steamed rice",
    "Price": 319,
    "type": "Non-Veg",
    "Photo": "MenuPhotos/GreenCurry.JPG"
  },
  {
    "Name": "Prawns Green Thai Curry Rice Bowl",
    "Description": "Coconut-based rich Thai green curry tossed with prawns and exotic vegetables served with choice of steamed rice",
    "Price": 329,
    "type": "Non-Veg",
    "Photo": "MenuPhotos/PrawnGreen.JPG"
  },
  {
    "Name": "Prawn Red Curry Thai Rice Bowl",
    "Description": "Coconut-based rich Thai red curry tossed with prawns and exotic vegetables served with choice of steamed rice",
    "Price": 329,
    "type": "Non-Veg",
    "Photo": "MenuPhotos/PrawnRed.JPG"
  },
  {
    "Name": "Green Chicken Thai Curry Rice Bowl",
    "Description": "Traditional green Thai curry infused in coconut milk tossed in chicken and exotic vegetables served with choice of steamed rice",
    "Price": 319,
    "type": "Non-Veg",
    "Photo": "MenuPhotos/GreenCurry.JPG"
  },
];




export const NorthIndiann = [
  {
    "Name": "Dal Makhani Rice Bowl",
    "Description": "Black dal and rajma cooked overnight with cream and butter in an authentic Dum Pukht style served with steamed rice",
    "Price": 219,
    "type": "Veg",
    "Photo": "MenuPhotos/DalMakhani.JPG"
  },
  {
    "Name": "Pindi Chole Masala Rice Bowl",
    "Description": "Chole simmered in rich brown onion tomato gravy served with steamed rice",
    "Price": 229,
    "type": "Veg",
    "Photo": "MenuPhotos/PindiChole.JPG"
  },
  {
    "Name": "Kashmiri Rajma Masala Rice Bowl",
    "Description": "Kashmiri rajma slowly cooked in thick and spicy onion tomato gravy served with steamed rice",
    "Price": 229,
    "type": "Veg",
    "Photo": "MenuPhotos/KashmiriRajma.JPG"
  },
  {
    "Name": "Mix Veg Lajawab Rice Bowl",
    "Description": "Fresh garden vegetables cooked in masala gravy served with steamed rice",
    "Price": 239,
    "type": "Veg",
    "Photo": "MenuPhotos/MixSabji.JPG"
  },
  {
    "Name": "Butter Paneer Rice Bowl",
    "Description": "Paneer simmered in buttery and creamy tomato-based gravy served with steamed rice",
    "Price": 249,
    "type": "Veg",
    "Photo": "MenuPhotos/ButterPaneer.JPG"
  },
  {
    "Name": "Mutter Paneer Makhni Rice Bowl",
    "Description": "Mutter paneer simmered in makhani gravy served with steamed rice",
    "Price": 249,
    "type": "Veg",
    "Photo": "MenuPhotos/MutterPaneer.JPG"
  },
  {
    "Name": "Paneer Tikka Lababdar Makhni Rice Bowl",
    "Description": "Tandoori paneer tikka in rich makhani gravy served with steamed rice",
    "Price": 259,
    "type": "Veg",
    "Photo": "MenuPhotos/ButterPaneer.JPG"
  },
  {
    "Name": "Palak Paneer Rice Bowl",
    "Description": "Paneer simmered in creamy onion tomato palak gravy served with steamed rice",
    "Price": 239,
    "type": "Veg",
    "Photo": "MenuPhotos/ChickenPalak.JPG"
  },
  {
    "Name": "Dal Tadka Rice Bowl",
    "Description": "Dhaba-style dal tadka served with steamed rice",
    "Price": 209,
    "type": "Veg",
    "Photo": "MenuPhotos/DalTadka.JPG"
  },
  {
    "Name": "Kadhai Paneer Masala Rice Bowl",
    "Description": "Paneer simmered in kadhai masala served with steamed rice",
    "Price": 229,
    "type": "Veg",
    "Photo": "MenuPhotos/PaneerKadhai.JPG"
  },
  {
    "Name": "Smoked Butter Chicken Rice Bowl",
    "Description": "Smoked tandoori chicken tossed in rich creamy and buttery tomato makhani gravy served with steamed rice",
    "Price": 299,
    "type": "Non-Veg",
    "Photo": "MenuPhotos/ButterChicken.JPG"
  },
  {
    "Name": "Chicken Tikka Makhni Rice Bowl",
    "Description": "Chicken tikka simmered in rich makhani gravy served with steamed rice",
    "Price": 289,
    "type": "Non-Veg",
    "Photo": "MenuPhotos/PaneerTikka.JPG"
  },
  {
    "Name": "Chicken Palak Masala Rice Bowl",
    "Description": "Chicken simmered in creamy onion tomato palak gravy served with steamed rice",
    "Price": 279,
    "type": "Non-Veg",
    "Photo": "MenuPhotos/ChickenPalak.JPG"
  },
  {
    "Name": "Chicken Kheema Rice Bowl",
    "Description": "Chicken minced tossed in rich onion tomato gravy served with steamed rice",
    "Price": 259,
    "type": "Non-Veg",
    "Photo": "MenuPhotos/ChickenKheema.JPG"
  },
  {
    "Name": "Chicken Kadhai Masala Rice Bowl",
    "Description": "Chicken simmered in kadhai masala served with steamed rice",
    "Price": 259,
    "type": "Non-Veg",
    "Photo": "MenuPhotos/ChickenKadhai.JPG"
  }
];

  

export const MilkShakee = [
  {
    "Name": "Banana Caramel Milkshake 300ml (No Sugar)",
    "Description": "This banana caramel milkshake is a simple and delicious treat. The homemade caramel sauce is worth the extra time.",
    "Price": 159,
    "type": "Veg",
    "Photo": "MenuPhotos/BananaMS.JPG"
  },
  {
    "Name": "Oreo Milkshake 300ml ",
    "Description": "Classic Oreo milkshake loaded with Oreo, milk, and ice cream.",
    "Price": 149,
    "type": "Veg",
    "Photo": "MenuPhotos/OreoMS.JPG"
  },
  {
    "Name": "Chocolate Milkshake 300ml ",
    "Description": "Classic chocolate milkshake loaded with chocolate, milk, and ice cream.",
    "Price": 149,
    "type": "Veg",
    "Photo": "MenuPhotos/OreoMS.JPG"
  },
  {
    "Name": "Strawberry Milkshake 300ml ",
    "Description": "Love strawberry? Try our strawberry milkshake loaded with strawberry, milk, and ice cream.",
    "Price": 149,
    "type": "Veg",
    "Photo": "MenuPhotos/StrawberryMS.JPG"
  },
  {
    "Name": "Mango Milkshake 300ml ",
    "Description": "Love mango? Try our mango milkshake loaded with mango pulp, milk, and ice cream.",
    "Price": 149,
    "type": "Veg",
    "Photo": "MenuPhotos/MangoMS.JPG"
  },
  {
    "Name": "Kitkat Milkshake 300ml ",
    "Description": "Classic Kitkat milkshake loaded with Kitkat, milk, and ice cream.",
    "Price": 149,
    "type": "Veg",
    "Photo": "MenuPhotos/KitKatMS.JPG"
  },
  {
    "Name": "Thick Cold Coffee 300ml ",
    "Description": "Classic thick cold coffee with ice cream.",
    "Price": 149,
    "type": "Veg",
    "Photo": "MenuPhotos/ThickCoffee.JPG"
  }
];


  

export const Beveragess =  [
    
]

export const Dessertss = [
 {
   "Name": "Gulab Jamun - Pack of 1",
   "Description": "Gulab Jamun",
   "Price": 29,
   "type": "Veg",
   "Photo": "MenuPhotos/Gulab.JPG"
 },
 {
   "Name": "Rasogulla - Pack of 1",
   "Description": "Rasogulla",
   "Price": 29,
   "type": "Veg",
   "Photo": "MenuPhotos/Rasogulla.JPG"
 },
 {
   "Name": "Choco Lava Cake",
   "Description": "Indulge in the divine pleasure of molten chocolate cake",
   "Price": 99,
   "type": "Veg",
   "Photo": "MenuPhotos/ChocoLava.JPG"
 }
];
  
  

  export const Pastaa = [
    {
      "Name": "Tomato Sauce Pasta",
      "Description": "Choice of penne, fusili, and spaghetti tossed in traditional tomato sauce with add on exotic vegetables and chicken",
      "Price": 259,
      "type": "Veg",
      "Photo": "MenuPhotos/TomatoCream.JPG"
    },
    {
      "Name": "Arabiata Sauce Pasta",
      "Description": "Choice of penne, fusili, and spaghetti tossed in traditional spicy tomato sauce with add on exotic vegetables and chicken",
      "Price": 259,
      "type": "Veg",
      "Photo": "MenuPhotos/Arabiata.JPG"
    },
    {
      "Name": "Tomato Cream Sauce Pasta",
      "Description": "Choice of penne, fusili, and spaghetti tossed in traditional tomato sauce and cream with add on exotic vegetables and chicken",
      "Price": 269,
      "type": "Veg",
      "Photo": "MenuPhotos/TomatoCream.JPG"
    },
    {
      "Name": "Alfredo Sauce Pasta (White Sauce Pasta)",
      "Description": "Choice of penne, fusili, and spaghetti tossed in cream and cheese sauce with add on exotic vegetables and chicken",
      "Price": 259,
      "type": "Veg",
      "Photo": "MenuPhotos/AlfredoPasta.jpg"
    },
    {
      "Name": "Roasted Mushroom Sauce Pasta",
      "Description": "Choice of penne, fusili, and spaghetti tossed in roasted mushroom garlic sauce with add on exotic vegetables and chicken",
      "Price": 259,
      "type": "Veg",
      "Photo": "MenuPhotos/RoastedMashroom.JPG"
    },
    {
      "Name": "Roasted Mushroom Cream Sauce Pasta",
      "Description": "Choice of penne, fusili, and spaghetti tossed in roasted mushroom garlic cream sauce with add on exotic vegetables and chicken",
      "Price": 269,
      "type": "Veg",
      "Photo": "MenuPhotos/RoastedMashroom.JPG"
    },
    {
      "Name": "Pesto Sauce Pasta",
      "Description": "Choice of penne, fusili, and spaghetti tossed in traditional basil, parmesan sauce with add on exotic vegetables and chicken (contains nuts)",
      "Price": 289,
      "type": "Veg",
      "Photo": "MenuPhotos/PestoCream.JPG"
    },
    {
      "Name": "Pesto Cream Sauce Pasta",
      "Description": "Choice of penne, fusili, and spaghetti tossed in traditional basil, parmesan cream sauce with add on exotic vegetables and chicken (contains nuts)",
      "Price": 299,
      "type": "Veg",
      "Photo": "MenuPhotos/PestoCream.JPG"
    }
  ];
  
  

  
  

